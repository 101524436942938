const RDUtils = {
    // 密码应至少6位，包含大写字母至少2个，小写字母至少2个，必须有数字，且必须有特殊符号
    checkStrongPassword(str) {
        var patt1 = /(?=.{6,200})/;
        var patt2 = /(?=\d)/;
        var patt3 = /(?=[A-Z]{1,}.*[A-Z]{1,})/;
        var patt4 = /(?=[a-z]{1,}.*[a-z]{1,})/;
        var patt5 = /(?=[^A-Za-z0-9]{1,})/;
        return patt1.test(str) && patt2.test(str) && patt3.test(str) && patt4.test(str) && patt5.test(str)
    },
    /**
   * 较弱的强密码检测 至少8位，包含数字，大写和小写之中的至少两种
   * @param {*} str 
   * @returns 
   */
    checkWeekPassword(str) {
        var patt1Pass = /(?=.{8,200})/.test(str);
        var patt2Pass = /(?=\d)/.test(str);
        var patt3Pass = /(?=[A-Z]{1,})/.test(str);
        var patt4Pass = /(?=[a-z]{1,})/.test(str);
        return patt1Pass && ((patt2Pass && patt3Pass) || (patt3Pass && patt4Pass) || (patt2Pass && patt4Pass))
    },
    preloadImage(urlObject, finishPercentCallback) {
        const thiz = this
        return new Promise((resolve, reject) => {
            if (urlObject instanceof Array) {
                var all = urlObject.length
                var count = 0;
                var finishOne = () => {
                    count++;
                    var presentString = '' + parseInt(count / all * 10000) / 100
                    if (finishPercentCallback) {
                        finishPercentCallback(presentString)
                    }
                }
                var allImageLoads = []
                for (var url of urlObject) {
                    ((urlIn) => {
                        allImageLoads.push(new Promise((resolve, reject) => {
                            var image = new Image();
                            image.onload = () => {
                                // console.log('image onload', urlIn)
                                resolve()
                                finishOne()
                            }
                            image.onerror = () => {
                                // console.log('image onerror', urlIn)
                                resolve()
                                finishOne()
                            }
                            image.src = urlIn
                        }))
                    })(url);

                }
                Promise.all(allImageLoads).then(() => {
                    resolve()
                }).catch(() => {
                    resolve()
                })
            } else {
                var image = new Image();
                image.onload = () => {
                    console.log('image onload', urlObject)
                    resolve()
                }
                image.onerror = () => {
                    console.log('image onerror', urlObject)
                    resolve()
                }
                image.src = urlObject
            }

        })
    },
    readTextFile(file) {
        const thiz = this
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.onload = function (evt) {
                var v8 = new Uint8Array(evt.target.result)
                if (thiz.isUTF8(v8)) {
                    console.log('is UTF-8')
                    resolve(thiz.readTextFileWithCode(file, 'utf-8'))
                } else {
                    console.log('is not UTF-8')
                    resolve(thiz.readTextFileWithCode(file, 'gbk'))
                }
            }
            reader.readAsArrayBuffer(file)
        })
    },
    readTextFileWithCode(file, code) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.onload = function (evt) {
                resolve(evt.target.result)
            }
            reader.readAsText(file, code)
        })
    },
    formatTimeToMonth(date) {
        if (!date) {
            return undefined
        }
        return date.getFullYear() + '-' +
            (date.getMonth() < 9 ? ('0' + (date.getMonth() + 1)) : ('' + (date.getMonth() + 1)))
    },
    formatTimeToDay(date) {
        if (!date) {
            return undefined
        }
        return date.getFullYear() + '-' +
            (date.getMonth() < 9 ? ('0' + (date.getMonth() + 1)) : ('' + (date.getMonth() + 1))) + '-' +
            (date.getDate() < 10 ? ('0' + date.getDate()) : (date.getDate()))
    },
    isUTF8(bytes) {
        var i = 0
        while (i < bytes.length) {
            if (
                // ASCII
                bytes[i] == 0x09 ||
                bytes[i] == 0x0a ||
                bytes[i] == 0x0d ||
                (0x20 <= bytes[i] && bytes[i] <= 0x7e)
            ) {
                i += 1
                continue
            }

            if (
                // non-overlong 2-byte
                0xc2 <= bytes[i] &&
                bytes[i] <= 0xdf &&
                0x80 <= bytes[i + 1] &&
                bytes[i + 1] <= 0xbf
            ) {
                i += 2
                continue
            }

            if (
                // excluding overlongs
                (bytes[i] == 0xe0 && 0xa0 <= bytes[i + 1] && bytes[i + 1] <= 0xbf && 0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xbf) || // straight 3-byte
                (((0xe1 <= bytes[i] && bytes[i] <= 0xec) || bytes[i] == 0xee || bytes[i] == 0xef) &&
                    0x80 <= bytes[i + 1] &&
                    bytes[i + 1] <= 0xbf &&
                    0x80 <= bytes[i + 2] &&
                    bytes[i + 2] <= 0xbf) || // excluding surrogates
                (bytes[i] == 0xed && 0x80 <= bytes[i + 1] && bytes[i + 1] <= 0x9f && 0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xbf)
            ) {
                i += 3
                continue
            }

            if (
                // planes 1-3
                (bytes[i] == 0xf0 && 0x90 <= bytes[i + 1] && bytes[i + 1] <= 0xbf && 0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xbf && 0x80 <= bytes[i + 3] && bytes[i + 3] <= 0xbf) || // planes 4-15
                (0xf1 <= bytes[i] &&
                    bytes[i] <= 0xf3 &&
                    0x80 <= bytes[i + 1] &&
                    bytes[i + 1] <= 0xbf &&
                    0x80 <= bytes[i + 2] &&
                    bytes[i + 2] <= 0xbf &&
                    0x80 <= bytes[i + 3] &&
                    bytes[i + 3] <= 0xbf) || // plane 16
                (bytes[i] == 0xf4 && 0x80 <= bytes[i + 1] && bytes[i + 1] <= 0x8f && 0x80 <= bytes[i + 2] && bytes[i + 2] <= 0xbf && 0x80 <= bytes[i + 3] && bytes[i + 3] <= 0xbf)
            ) {
                i += 4
                continue
            }
            return false
        }
        return true
    }
}
export default RDUtils

